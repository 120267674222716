<template>
  <div>
    <el-row class="movie-list">
      <el-col :md="20">
        <el-row>
          <h2>我的登录历史</h2>
        </el-row>
        <el-card>
          <el-table
            :data="dataList"
            :show-header="true"
            border
            style="width: 100%"
          >
            <el-table-column
              prop="title"
              label="登录时间"
            />
            <el-table-column
              prop="content"
              label="登录设备"
            />
            <el-table-column
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="logout(scope.row)"
                >登出</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getLoginRecord, signOut } from '@/api/account'

export default {
  name: 'MyRecord',
  data() {
    return {
      dataList: []
    }
  },
  created() {
    document.title = '我的登录历史'

    getLoginRecord().then(resp => {
      if (resp.code === 0) {
        this.dataList = resp.data
      }
    })
  },
  methods: {
    logout(row) {
      signOut(row.loginId).then(resp => {
        if (resp.code === 0) {
          this.$message('已处理')
          this.dataList.pop()
        }
      })
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}
</style>
